<template>

<html>
<head>
	
  
</head>
<body>
<div id="background"></div>
<div id="backgroundtwo"></div>



<div id="firstcontent">
	
	<div id="firstdrag"></div>
	<VCard id="firstdrags" @click="$router.push('/login/user/id')"  v-if="!this.$store.state.isAuthenticated">
		
		<img id="firstdragse" v-bind:src="require('../assets/power-firma.jpg')"/>
		<VCardTitle id="firstoff">Login</VCardTitle>
		<div id="firstdragss"></div>
		
	</VCard>

	<VCard id="firstdrags" @click="Logouts" v-if="this.$store.state.isAuthenticated" >
		
		<img id="firstdragse" v-bind:src="require('../assets/power-firma.jpg')"/>
		
		<VCardTitle id="firstoff" v-if="this.$store.state.isAuthenticated">Logout</VCardTitle>
		<div id="firstdragss"></div>
		
	</VCard>
	<VCard id="firstdragsexs">
		<VCardTitle id="firstdragsexc">Deposits</VCardTitle>
		<VCard id="firstdragsinfo" @click="needdep = !needdep">
		<VCard id="firstdragsinfots">Close</VCard>
		<VCardTitle id="firstdragsinfot">Deposits to Unlimited Money Group</VCardTitle>
		

			<VCard id="firstdragsinfos">
				<v-infinite-scroll id="loveyou" height = '550' color = 'grey'>
					<VCardSubtitle id="firstdragsinfoss">Press Deposit</VCardSubtitle> 

					
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESSA.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Wait for an address if you have not already received one (May take a few moments)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/makesure.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Funds will automatically appear once the transaction has been confirmed</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESS.png')"/>
				</v-infinite-scroll>
			</VCard>

		
				
		
		
		</VCard>
	
	</VCard>
	<VCard id="firstdragsexse">
		
		<VCard id="firstdragsinfo"  @click="wantwith = !wantwith">
		<VCard id="firstdragsinfots">Close</VCard>
		<VCardTitle id="firstdragsinfot">Withdrawals from Unlimited Money Group</VCardTitle>
		

			<VCard id="firstdragsinfos">
				<v-infinite-scroll id="loveyou" height = '550' color = 'grey'>
					<VCardSubtitle id="firstdragsinfoss">Press Withdraw</VCardSubtitle> 

					
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESSAS.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Enter an address and the desired withdrawal amount (Must have enough currency)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/EXTREME.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Funds will be sent instantly if the transaction is successful (Please Refresh)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/spare.png')"/>
				</v-infinite-scroll>
			</VCard>

		
				
		
		
		</VCard>
		<VCardTitle id="firstdragsexc">Withdrawals</VCardTitle>
	</VCard>
	<VCard id="firstdragsex">
		<VCardTitle id="firstdragsexc">Transfers</VCardTitle>

		<VCard id="firstdragsinfo"  @click="needtrans = !needtrans">
		<VCard id="firstdragsinfots">Close</VCard>
		<VCardTitle id="firstdragsinfot">Transfers to Unlimited Money Glitch</VCardTitle>
		

			<VCard id="firstdragsinfos">
				<v-infinite-scroll id="loveyou" height = '550' color = 'grey'>
					<VCardSubtitle id="firstdragsinfoss">Connect Your Account (Press 'Apps/Accounts' or 'X' in the top left)</VCardSubtitle> 
					
					
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESS.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Link Your Account (Press Link)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/XD.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Authenticate (Sign In)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/ana.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Press Transfer (Authenticated)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/trans.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Enter Transfer Amount (To Unlimited Money Glitch) (Press Enter)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/man.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Confirm Transfer with Password (Press Enter)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/confi.png')"/>
				</v-infinite-scroll>
			</VCard>

		
				
		
		
		</VCard>
	</VCard>
	<VCard id="firstdragsexe">
		
		<VCardTitle id="torr">Support@unlimitedmoneygroup.com</VCardTitle></VCard>
	
	<VCard id="firstdragsinfo" v-if='needtrans' @click="needtrans = !needtrans">
		<VCard id="firstdragsinfots">Close</VCard>
		<VCardTitle id="firstdragsinfot">Transfers to Unlimited Money Glitch</VCardTitle>
		

			<VCard id="firstdragsinfos">
				<v-infinite-scroll id="loveyou" height = '550' color = 'grey'>
					<VCardSubtitle id="firstdragsinfoss">Connect Your Account (Press 'Apps/Accounts' or 'X' in the top left)</VCardSubtitle> 
					
					
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESS.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Link Your Account (Press Link)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/XD.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Authenticate (Sign In)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/ana.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Press Transfer (Authenticated)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/trans.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Enter Transfer Amount (To Unlimited Money Glitch) (Press Transfer)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/man.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Confirm Transfer with Password (Press Enter)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/confi.png')"/>
				</v-infinite-scroll>
			</VCard>

		
				
		
		
	</VCard>
	<VCard id="firstdragsinfo" v-if="wantwith" @click="wantwith = !wantwith">
		<VCard id="firstdragsinfots">Close</VCard>
		<VCardTitle id="firstdragsinfot">Withdrawals from Unlimited Money Group</VCardTitle>
		

			<VCard id="firstdragsinfos">
				<v-infinite-scroll id="loveyou" height = '550' color = 'grey'>
					<VCardSubtitle id="firstdragsinfoss">Press Withdraw</VCardSubtitle> 

					
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESSAS.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Enter an address and the desired withdrawal amount (Must have enough currency)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/EXTREME.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Funds will be sent instantly if the transaction is successful (Please Refresh)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/spare.png')"/>
				</v-infinite-scroll>
			</VCard>

		
				
		
		
	</VCard>
	<VCard id="firstdragsinfo" v-if="needdep" @click="needdep = !needdep">
		<VCard id="firstdragsinfots">Close</VCard>
		<VCardTitle id="firstdragsinfot">Deposits to Unlimited Money Group</VCardTitle>
		

			<VCard id="firstdragsinfos">
				<v-infinite-scroll id="loveyou" height = '550' color = 'grey'>
					<VCardSubtitle id="firstdragsinfoss">Press Deposit</VCardSubtitle> 

					
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESSA.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Wait for an address if you have not already received one (May take a few moments)</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/makesure.png')"/>
					<VCardSubtitle id="firstdragsinfoss">Funds will automatically appear once the transaction has been confirmed</VCardSubtitle> 
					<img id="firstdragsinfosss" v-bind:src="require('../assets/PRESS.png')"/>
				</v-infinite-scroll>
			</VCard>

		
				
		
		
	</VCard>
	
	
	
</div>

<div id="thirdcontent">
	<div id="thirdcontentbg"></div>
	
	<VCard id="firstdragsgh" @click="$router.push('/profile')">
		<img id="firstdragses"  v-bind:src="require('../assets/francisco-segovia.jpg')"/>
	</VCard>
	<VCardTitle id="titlecs">Profile</VCardTitle>
</div>
<div id="fourthcontent">
	<div id="titlecx"></div>
	<VCardTitle id="titlec">Technology</VCardTitle>
	
	<VCard id="fourthcontents" @click="glitchredir">
		
		<img id="firstdragses" v-bind:src="require('../assets/goku-house.jpg')"/>
		<VCardSubtitle id="firstdragsess">Unlimited Money Generator</VCardSubtitle>
	
	</VCard>
		
	<VCard id="fourthcontentss" @click="glitchredir">
		<img id="firstdragsesd" v-bind:src="require('../assets/francisco.jpg')"/>
		<VCardSubtitle id="firstdragsess">Unlimited Money Glitch</VCardSubtitle>
	</VCard>
</div>

<div id="secondcontent">
	
	<VCard id="bglayer">
				<img id = "pxfuel" v-bind:src="require('../assets/pxfuel.jpg')"/>
		</VCard>
		<VCard id="bglayercontent">
		
		<VCardTitle>Buy Cash Cards</VCardTitle>
		
		</VCard>

		<VCard id="backdropcashcard"></VCard>
		<VCard id="backdropcashcardline"></VCard>
		

		<VCard id="caption">
			<label id="captiontext">Solve your money problem and help get what you want within the world with the occasional purchase of cash packs for Unlimited Money Glitch.</label></VCard>
	<div id="contentblockdiv">
		
		<div id="contentsecondlistul" class="d-flex flex-wrap ga-10">
		
				
				<VCard id="cardbgbg"></VCard>
				
				<VCard id="card" style="height: 65vh" :key='n' width='22.5%'  v-for = '(items, index) in purchaseoptions'>
					
					<VCard id="cardbg"></VCard>
					<label id="cardetails">Cash cards cannot be redeemed for real money. To activate visit unlimitedmoneyglitch.com</label>
					<div id="purchasecard">
						<div id="purchasecardbg"></div>
						<VCard id="moneyfuelbg"><img id = "moneyfuel" v-bind:src="require('../assets/money.png')"/>
						</VCard>
						
					</div>
					<VCard id="purchasebgtop">
						<VCardTitle>Cash Card</VCardTitle>
						<label id='cashcardtitle'></label>
						
					</VCard>
					<VCard id="purchasebg">
						
						
						<VCardTitle id="purchasetitle">{{ items.title }}</VCardTitle>
						<label id="purchaseamount">${{Intl.NumberFormat().format(items.amount)}}.00</label>
						
						
					</VCard>

					
					
					
					<VCard id="fuelbg"></VCard>
					<VCard id="fuel">
						
						<img id = "fuelgod" v-bind:src="require('../assets/capsule.jpg')"/>
						<VCard id="cardetailslines"></VCard>
						<VCardTitle ID="FUELGD">${{Intl.NumberFormat().format(items.amount)}}.00</VCardTitle>
						
					</VCard>
					<VCard id="fuelborder">
					
						
					</VCard>
					<VCard id="purchase">Purchase</VCard>
					<VCard id="cardetailsline"></VCard>
					<button id="ordinary" @click="PUSHROUTERITEM(index)"></button>
			
				</VCard>

				


			
			
		
		</div>
		
	</div>	
</div>

<div id="cards"></div>
<div id="seccards"></div>
</body>
</html>
	
</template>

<script>
var openedlogin = false
var isauth = false
var loginbutton = false
document.body.addEventListener("keydown", (ev) => {
	//console.log(ev)
	
	
})
import store from '@/store'
import axios from 'axios'

//glitches save for later




import {toast} from 'bulma'
var pushs =''
document.onmousemove = (ev) =>{
	//console.log(ev.target)
	if(ev.target.id == 'aboutusopt'){
		pushs = ev.target.innerText
		console.log(pushs)
	}
	
}

export default{
	data(){
	return{
		homeoptions:['L','P','S','X'],purchaseoptions:[{'amount':'10000','title':'Unlimited Money Cash Card'},{'amount':'100','title':'Unlimited Money Cash Card'},{'amount':'50','title':'Unlimited Money Cash Card'}],
		itemid:['5FZAeFz9QFRNGxt','lodt1w6RY9RlLIF','gZ3o7byRRdMwctM'],paths:['/home','/profile','/item/none','unlimitedmoneyglitch.com'],needtrans:false,needdep:false,wantwith:false
		
		
	}
	
	
	},

	

	async mounted(){
		
		
		if (this.$store.state.isAuthenticated){
			this.profile()
		}else{
			//this.$router.push({path:'/login/user/id'})
		}
		//setInterval(this.SETIMER, 5000)
		//this.YOUPAIDTHANKS()
		//this.AMIADMIN()
		//console.log(this.$route.query.session_id)

			
		
		
		//await this.GetData()
		
		
		//setInterval(1000, this.Purchase())
	},



	methods:{
		
		glitchredir(){
			window.location.replace('https://unlimitedmoneyglitch.com')
		},
		generedir(){
			window.location.replace('https://unlimitedmoneygenerator.com')
		},
		profile(){
			
			
			
			const fromData = {
				token: localStorage.token,
				
			}
			
			axios
				.post('/api/v1/GETPROFILE/', fromData).then(response =>{
				
				localStorage.setItem('money', response.data.Money.toFixed(2))
				this.$store.state.money = response.data.Money
				this.$store.state.money = this.$store.state.money.toFixed(2)
				this.$store.state.hideuser = response.data.username
				this.$store.state.hidetoken = localStorage.token
				}).catch(error => {
				if(error.response){
					//console.log(error.response.data)

				}
			})
			
			
			
				
				
			
		},

		async Logouts(){

			axios
			.post('/api/v1/token/logout/')
			.then(response =>{
				
				
				//console.log('Logged Out')

			}).catch(error => {
				if(error.response){
				//console.log(error.response.data)
				}
			})

			axios.defaults.headers.common['Authorization'] = ''
			localStorage.removeItem('token')
			this.$store.commit('removeToken')
			localStorage.removeItem('username')
			localStorage.removeItem('id')
			localStorage.removeItem('linked')
			localStorage.removeItem('apitoken')
			localStorage.removeItem('linkapiu')
			localStorage.removeItem('linkapip')
			localStorage.removeItem('money')
			this.$router.push({path:'/login/user/sign-in'})




			},
		alias(){
			window.location.replace("https://unlimitedmoneyglitch.com")
		},
		nav(index){
			
			if (index == 1){
				//console.log(index)
				if(this.$store.state.token){
					this.$router.push({path:this.paths[index]})
				}else{
					this.$router.push({path:'/login/user/id'})
				}
			}else{
				if (index == 3){
					window.location.replace('https://unlimitedmoneyglitch.com')
				}else{
					this.$router.push({path:this.paths[index]})
				}
			}
			
		},
		PUSHROUTERITEM(index){
			if(this.$store.state.isAuthenticated){
				this.$router.push({ path: `/item/${this.itemid[index]}` })
				//console.log(index, '10k')
			
				//console.log(index)
			}else{
				this.$router.push({ path: `/login/item/${this.itemid[index]}` })
			}
				

		}

		
	
	}



}


/*#purchase{
	position: absolute;
	right:2.5%;
	width: 95%;
	height:17.5%;
	top:80%;
	font-family: 'Arial Narrow';
	color:rgb(14, 48, 15);
	opacity: 0.8;
	
	letter-spacing: 1px;
	
	background-color: rgb(8, 8, 8);
} */

</script>

<style scoped>


h1 {
            color: whitesmoke;
        }

p {
            color: red;
        }
#torr{
	font-size: 120%;
}

#loveyou{
	position: relative;
	z-index: 10;
}
#firstoff{
	position: absolute;
	height:15%;
	width:100%;
	top:0%;
	font-size: 180%;
	right:0%;
	color:rgb(7, 2, 10);	
	z-index: 10;
}
#titlec{
	padding-top:3%;
	padding-left:1.5%;
	color:rgb(182, 182, 182);
	width: 100%;
	font-size: 180%;
	position:absolute;
}

#titlecs{
	padding-top: 0%;
	color:rgb(23, 23, 23);
	width: 100%;
	font-size: 180%;
	position: absolute;
	height:20%;
	text-align: left;
	padding-right: 1%;
	padding-top: .55%;
	z-index: 10;
}
#imghub{
	width:60%;
	z-index: 20;
	opacity: 0.5;
	position: absolute;
	visibility: hidden;
}
#homeimg{
	position: relative;
	right:-16.5%;
	margin-top:15.5%;
	width:60%;
	color:white;
}
#purchasebg{
	position: absolute;
	right:7.5%;
	width: 85%;
	height:14.5%;
	top:81%;
	
	color:rgb(14, 48, 15);
	opacity: 0.8;
	
	letter-spacing: 1px;
	filter:drop-shadow(0px 0px 5px rgb(20, 20, 20));
	background-color: rgb(5, 5, 5);
}
#fuelbg{
	position: absolute;
	width: 87.5%;
	height: 22.5%;
	
	opacity: 0.5;
	top:28.5%;
	right:6.25%;
	background-color: rgb(15, 15, 15);
}
#FUELGD{
	font-weight: 700;
	top:10%;
	filter: brightness(1.5);
	letter-spacing: 2px;
	color:rgba(0, 0, 0, 0.718);
	position: absolute;
}
#fuel{
	position: absolute;
	width: 85%;
	height: 20%;
	
	opacity: 1;
	top:29.5%;
	right:7.5%;
	background-color: rgb(9, 9, 9);
}

#fuelborder{
	position: absolute;
	width: 85%;
	height: 20%;
	border:2px solid rgb(78, 78, 78);
	
	top:29.5%;
	opacity: 0.15;
	right:7.5%;
	
}

#fuelgod{
	
	opacity: 0.515;

}

#moneyfuel{

}
#cardetails{
	width: 90%;
	color:rgb(21, 118, 6);
	top:53.5%;
	right:5%;
	font-weight: 500;
	height:20%;
	letter-spacing: 1px;
	padding:10px;
	font-size: 80%;
	font-family: 'arial narrow';
	opacity: 0.5;
	text-align: center	;
	position: absolute;
	
}

#cardetailsline{
	width: 85%;
	background-color: rgb(18, 18, 18);
	height:0.5%;
	opacity: 0.8;
	right:7.5%;
	top:52%;
	position: absolute;
}


#cardetailslines{
	width: 90%;
	background-color: rgba(112, 112, 112, 0.79);
	height:2.5%;
	opacity: 0.8;
	right:0%;
	top:90%;
	position: absolute;
}


#purchasecard{
	
	position: absolute;
	width:90%;
	height:90%;
	top:10%;
	right:5%;
	
}

#moneyfuel{
	margin-top: 7.5%;
	width: 100%;
	opacity: 0.1;
}

#moneyfuelbg{
	width:80%;
	right:10%;
	top:23%;
	height: 50%;
	position: absolute;
	border: 2px solid rgb(16, 16, 16);
	opacity: 0;
	background-color: rgb(14, 14, 14);
}

#purchasecardbg{
	background-color: rgb(12, 12, 12);
	position: absolute;
	opacity: 0.4;
	border-left:2px solid rgb(23, 23, 23);
	border-right:2px solid rgb(23, 23, 23);
	right:0%;
	top:0%;
	width:100%;
	height:98%;
	
}

#purchasebgtop{
	position: absolute;
	right:2.5%;
	width: 95%;
	height:17.5%;
	top:10%;
	
	color:rgb(113, 113, 113);
	opacity: 1;
	border: 2px solid rgb(24, 24, 24);
	letter-spacing: 1px;
	
	background-color: rgb(16, 16, 16);
}
#purchaseamount{
	position: relative;
	right:-3.5%;
	top:-21%;
	font-size: 100%;
	letter-spacing: 1px;
	color:rgb(159, 159, 159);
}

#cashcardtitle{
	position: absolute;
	right:0%;
	top:38%;
	width: 100%;
	font-family: 'arial narrow';
	font-size: 200%;
	letter-spacing: 4px;
	font-weight: 600;
	opacity: 0.5;
	color:rgb(51, 87, 50);
	text-align: center;
}

#purchasebgbg{
	position: absolute;
	right:0%;
	width: 100%;
	height:95%;
	top:2.5%;
	
	color:rgb(182, 198, 183);
	opacity: 1;
	
	letter-spacing: 1px;
	
	background-color: rgb(8, 8, 8);
}
#purchasetitle{
	position: relative;
	right:3%;
	font-size: 100%;
	letter-spacing: 0px;
	color:rgb(157, 157, 157);
}
#purchase{
	position: absolute;
	right:25%;
	width: 50%;
	height:7.5%;
	top:80%;
	font-family: 'Arial Narrow';
	color:rgb(14, 48, 15);
	opacity: 0;
	text-align: center;
	letter-spacing: 1px;
	border:2px solid rgb(21, 67, 20);
	background-color: rgb(21, 105, 34);
}
#bglayercontent{
	background-color: black;
	width: 90%;
	top:7.5%;
	position: absolute;
	right:5%;
}
#bglayer{
	width:95%;
	height:90%;
	right:2.5%;
	top:5%;
	position: absolute;
	opacity: 0.5;
	border:2px solid rgb(18, 18, 18);
	filter: drop-shadow(1px 2px 1px black);
	background-color: rgb(18, 18, 18);
}
#backdropcashcard{
	width:85%;
	height:70%;
	background-color: rgb(10, 10, 10);
	position: absolute;
	left:7.5%;
	top:22.5%;
	opacity: 0.12;
	border:2px solid black;
}

#backdropcashcardline{
	width:84%;
	height:0.5%;
	background-color: rgb(11, 11, 11);
	position: absolute;
	left:8%;
	top:91%;
	opacity: 0.97;
}


#caption{
	width:82.5%;
	height:5%;
	right:7.5%;
	letter-spacing: 0px;
	top:15.5%;
	position: absolute;
	opacity: 1;
	color:rgb(88, 88, 88);
	border:1px solid rgb(32, 32, 32);
	filter: drop-shadow(1px 2px 1px black);
	background-color: rgb(18, 18, 18);
}
#captiontext{
	position: absolute;
	left:0.75%;
	top:10%;
	width: 100;
}
#bglayercard{
	position: absolute;
	height:90%;
	right:10%;
	top:5%;
	background-color: rgb(26, 26, 26);
	width: 80%;
	opacity: 0.2;
}
#background{
	position: absolute;
	width:100%;
	height:235%;
	right:0%;
	top:0%;
	z-index: 1;
	background-color: rgb(223, 158, 105);
}
#backgroundtwo{
	position: absolute;
	width:100%;
	height:41.5%;
	right:0%;
	top:235%;
	z-index: 1;
	background-color: rgb(159, 159, 159);
}
#firstcontent{
	position: absolute;
	width:100%;
	height:97.25%;
	right:0%;
	top:16.25%;
	z-index: 3;
	
	
}

#firstdrag{
	position: absolute;
	width:100%;
	height:100%;
	right:0%;
	top:0%;
	z-index: 3;
	
	background-color: rgb(14, 14, 14);
	
	
}

#firstdrags{
	position: absolute;
	width:75%;
	height:90%;
	right:24%;
	top:5%;
	z-index: 3;
	color:rgba(0, 0, 0, 0.112);
	background-color: rgb(14, 14, 14);
	
	
}


#firstdragsinfo{
	position: absolute;
	width:65%;
	height:85%;
	right:29%;
	top:7.5%;
	z-index: 3;
	color:rgba(0, 0, 0, 0.757);
	background-color: rgb(14, 14, 14);
	
	
}


#firstdragsinfot{
	position: absolute;
	width:100%;
	height:7.5%;
	right:0%;
	top:0%;
	color:rgb(148, 148, 148);
	z-index: 3;

	background-color: rgb(26, 26, 26);
	
	
}

#firstdragsinfots{
	position: absolute;
	width:11.75%;
	height:5.75%;
	right:0.5%;
	top:.75%;
	padding-right: 1%;
	text-align: right;
	color:rgb(52, 52, 52);
	z-index: 4;
	border:2px solid rgb(43, 43, 43);
	font-size: 120%;
	background-color: rgb(159, 159, 159);
	
	
}

#firstdragsinfos{
	position: absolute;
	width:98%;
	height:88.5%;
	right:1%;
	top:9.5%;
	color:rgb(148, 148, 148);
	z-index: 3;

	background-color: rgb(42, 42, 42);
	
	
}

#firstdragsinfoss{
	margin-top: 1%;
}

#firstdragsinfosss{
	margin-top: 2%;
	width: 90%;
	height:85%;
	
	margin-left:3%;
}
#firstdragsex{
	position: absolute;
	width:29.5%;
	height:27.5%;
	right:27.15%;
	top:8.5%;
	z-index: 3;
	opacity: 0.8;
	color:rgba(0, 0, 0, 0.624);
	border:2px solid rgb(47, 47, 47);
	background-color: rgb(15, 15, 15);
	
	
}
#firstdragsexc{
	position: absolute;
	width:100%;
	height:30%;
	right:0%;
	top:0%;
	text-align: right;
	z-index: 3;
	opacity: 0.8;
	color:rgb(255, 255, 255);
	
	
}

#firstdragsexs{
	position: absolute;
	width:29%;
	height:22%;
	right:26.5%;
	top:38.75%;
	z-index: 3;
	opacity: 0.8;
	border:2px solid rgb(47, 47, 47);
	color:rgba(0, 0, 0, 0.112);
	
	background-color: rgb(15, 15, 15);
	
	
}

#firstdragsexse{
	position: absolute;
	width:31%;
	height:18%;
	right:25%;
	top:63%;
	z-index: 3;
	opacity: 0.8;
	color:rgba(0, 0, 0, 0.112);
	border:2px solid rgb(47, 47, 47);
	background-color: rgb(15, 15, 15);
	
	
}
#firstdragsexe{
	position: absolute;
	width:32.75%;
	height:10%;
	right:24.5%;
	top:83.5%;
	z-index: 3;
	text-align: center;
	font-size: 90%;
	opacity: 0.8;
	padding-top: 7px;
	color: rgb(197, 197, 197);
	border:2px solid rgb(40, 40, 40);
	background-color: rgb(15, 15, 15);
	
	
}


#firstdragss{
	position: absolute;
	width:45%;
	height:100%;
	right:0%;
	top:0%;
	z-index: 3;
	border-left:1px solid rgba(58, 24, 65, 0.193);
	background-color:rgba(102, 87, 162, 0.481);
	
	
}


#firstdragsgh{
	position: absolute;
	width:101%;
	height:95%;
	right:-0.5%;
	top:1%;
	z-index: 3;
	color:rgba(0, 0, 0, 0.112);
	background-color: rgb(14, 14, 14);
	
	
}

#firstdragse{
	width:100%;
	right:0%;
	margin-top:-20%;
	z-index: 3;
	
	background-color: rgb(14, 14, 14);
	
	
}

#firstdragses{
	width:100%;
	right:0%;
	margin-top:-30%;
	z-index: 3;
	
	background-color: rgb(14, 14, 14);
	
	
}


#firstdragsesd{
	width:100%;
	right:0%;
	margin-top:-25%;
	z-index: 3;

	background-color: rgb(14, 14, 14);
	
	
}


#firstdragsess{
	position: absolute;
	width: 101%;
	z-index: 10;
	right:-0.5%;
	height:14.5%;
	top:84%;
	font-size: 120%;
	text-align: right;
	font-weight: 600;
	padding-right: 5%;
	padding-top:2.25%;
	border: 2px solid rgb(74, 74, 74);
	letter-spacing: 2px;
	background-color: rgba(0, 0, 0, 0.881);
	color:rgb(157, 157, 157);

}
#uptop{
	background-color: rgb(176, 176, 176);
	width:100%;
	opacity:1;
	height:3.25%;
	position: absolute;
	top:15%;
	z-index: 10;
}
#secondcontent{
	position: absolute;
	width:100%;
	height:102.5%;
	right:0%;
	top:44.5%;
	z-index: 3;
	opacity:0;
	visibility: hidden;
	border-top: 2px solid rgb(13, 13, 13);
	/**border-top: 1px solid rgb(45, 45, 45); */
	border-bottom: 4px solid rgb(45, 45, 45);
	background-color: rgb(19, 19, 19);
}

#thirdcontent{
	position: absolute;
	width:100%;
	height:47.5%;
	top:114%;
	right:0%;
	z-index: 3;
	opacity: 1;
	
	border-top: 2px solid rgb(10, 10, 10);
	border-bottom: 4px solid rgb(16, 16, 16);
	background-color: rgb(14, 14, 14);
	
}

#fourthcontent{
	position: absolute;
	width:100%;
	height:80.5%;
	top:162%;
	right:0%;
	z-index: 3;
	opacity: 1;
	filter: drop-shadow(2px 0px 0px rgb(33, 33, 33));
	border-bottom: 4px solid rgb(97, 97, 97);
	background-color: rgb(14, 14, 14);
	
}
#titlecx{
	padding-top:3%;
	padding-left:1.5%;
	color:rgb(182, 182, 182);
	width: 100%;
	height:80%;
	top:16.5%;
	background-color: rgb(15, 15, 15);
	font-size: 180%;
	position:absolute;
}


#fourthcontents{
	position: relative;
	width:43.5%;
	height:62.5%;
	top:22.5%;
	right:-4.5%;
	z-index: 3;
	color:rgba(46, 46, 46, 0.132);
	opacity: 1;
	filter: drop-shadow(2px 0px 2px rgb(33, 33, 33));
	border-bottom: 4px solid rgb(97, 97, 97);
	background-color: rgb(14, 14, 14);
	
}

#fourthcontentss{
	position: relative;
	width:43.5%;
	height:62.5%;
	color:rgba(46, 46, 46, 0.132);
	top:-40.05%;
	right:-51.5%;
	z-index: 3;
	opacity: 1;
	filter: drop-shadow(2px 0px 2px rgb(33, 33, 33));
	border-bottom: 4px solid rgb(97, 97, 97);
	background-color: rgb(14, 14, 14);
	
}
#thirdcontentbg{
	width:100%;
	height:80%;
	top:7.5%;
	opacity: 0.07;
	position: absolute;
	background-color: rgb(25, 25, 25);
}


#firstcontentbg{
	position: absolute;
	width:105%;
	height:112.25%;
	top:-1.5%;
	right:-2%;
	opacity: 1;
	border-top:2px solid rgb(90, 90, 90);
	border-bottom:6px solid rgb(95, 95, 95);
	background-color: rgb(142, 142, 142);
	filter:drop-shadow(2px 1px 5px rgb(24, 24, 24));
}

#firstcontentbgbg{
	position: absolute;
	width:105%;
	height:102%;
	top:3%;
	right:-1%;
	
	opacity: 1;
	border-top: 8px solid rgb(26, 26, 26);
	border-bottom: 4px solid rgb(26, 26, 26);
	filter: drop-shadow(2px 0px 5px rgb(0, 0, 0));
	background-color: rgb(106, 106, 106);
}

#firstcontentbgbgbg{
	width:105%;
	top:0%;
	right:-1%;
	height:109%;
	opacity: 1;
	position: absolute;
	border-top:4px solid rgb(61, 61, 61);
	border-bottom:4px solid rgb(31, 31, 31);
	background-color: rgb(189, 189, 189);
}

#firstcontentbgbgbgs{
	width:75%;
	top:0%;
	right:-1%;
	height:109%;
	border-left: 6px solid rgb(45, 45, 45);
	opacity: 1;
	position: absolute;
	border-top:4px solid rgb(61, 61, 61);
	border-bottom:4px solid rgb(31, 31, 31);
	background-color: rgb(172, 172, 172);
}


#firstcontentbgbgbgsx{
	width:91.75%;
	top:0.50%;
	right:-1%;
	height:108%;
	border-left: 6px solid rgb(101, 101, 101);
	opacity: 1;
	filter:drop-shadow(0px 0px 2px rgb(30, 30, 30));
	position: absolute;
	border-top:2px solid rgb(61, 61, 61);
	border-bottom:2px solid rgb(31, 31, 31);
	background-color: rgb(160, 172, 180);
}

#firstcontentbgbgbgss{
	font-size:1000%;
	position: absolute;
	z-index: 10;
	right:1%;
	top:67.5%;
	
	color:rgb(221, 221, 221);
}

#firstcontentbgbgbgbg{
	width:105%;
	top:-3.75%;
	right:-1%;
	height:116%;
	opacity: 1;
	position: absolute;
	border-top:4px solid rgb(30, 30, 30);
	border-bottom:4px solid rgb(21, 21, 21);
	background-color: rgb(18, 18, 18);
}
#cards{
	position: absolute;
	width:100%;
	height:30.5%;
	right:0%;
	top:245.5%;
	z-index: 1;
	border-top: 4px solid rgb(45, 45, 45);
	background-color: rgb(20, 20, 20);
}

#seccards{
	position: absolute;
	width:100%;
	height:2.5%;
	right:0%;
	top:274.25%;
	z-index: 1;
	filter:drop-shadow(2px 0px 3px rgb(20, 20, 20));
	background-color: rgb(16, 16, 16);
}

#hubs{
	margin-top: 1.05%;
	right:-0.75%;
	z-index: 5;
	
	
}

#hubbg{
	background-color: rgb(13, 14, 14);
	opacity: 0.8;
	border:3px solid rgb(31, 31, 31);
	width: 100%;
	height: 100%;
	z-index: 5;
	position: absolute;
	margin-right: -1%;
	filter: drop-shadow(2px 2px 2px black);
	
}

#hubsout{

margin-top: 10.5%;
	
}
#hubsoutbg{
	background-color: rgb(0, 0, 0);
	position: absolute;
	width: 9.5%;
	left:-2%;
	top:5.5%;
	height:95%;
	z-index: 5;
	opacity: 0.8;
	filter: drop-shadow(2px 3px 5px black);
}



#hubsoutbgside{
	background-color: rgb(6, 20, 11);
	position: absolute;
	width: 0.5%;
	left:7.25%;
	top:5%;
	height:96%;
	z-index: 5;
	opacity: 1;
	
}
#hubsoutbgsidebg{

	position: absolute;
	width: 6%;
	left:0.25%;
	top:13%;
	height:80%;
	
	z-index: 5;
	opacity: 0.5;
	border: 1px solid rgb(78, 78, 78);
	background-color: rgb(100, 100, 100);
	filter: drop-shadow(0px 3px 0px rgb(177, 177, 177));
}

#hubsoutbgsidebgs{

position: absolute;
width: 20.60%;
left:12.25%;
top:2%;
height:95%;

z-index: 1;
opacity: 0.9;
border: 2px solid rgb(8, 58, 5);
background-color: rgb(15, 100, 35);
filter: drop-shadow(0px 3px 0px rgb(177, 177, 177));
}

#hr{
	width: 90%;
	height:7.5%;
	top:68%;
	right:5%;
	color:rgb(2, 35, 12);
	font-size: 105%;
	letter-spacing: 0px;
	border:2px solid rgb(112, 58, 7);
	opacity: 0.7;
	background-color: rgb(182, 105, 29);
	text-align: center;
	position: absolute;
	filter:drop-shadow(0px 0px 1px rgb(111, 111, 111))
	


}


#hrs{
	width: 98%;
	height:7.5%;
	top:1%;
	right:1%;
	color:rgb(166, 166, 166);
	font-size: 105%;
	letter-spacing: 0px;
	border:2px solid rgb(6, 6, 6);
	opacity: 1;
	font-weight: 500;
	font-size: 150%;
	background-color: rgb(8, 8, 8);
	text-align: center;
	position: absolute;
	filter:drop-shadow(0px 0px 1px rgb(111, 111, 111))
	
}

#hrx{
	width: 98%;
	height:1%;
	top:10%;
	right:1%;
	color:rgb(166, 166, 166);
	font-size: 105%;
	letter-spacing: 0px;
	border:2px solid rgb(160, 160, 160);
	opacity: 0.81;
	font-weight: 500;
	font-size: 150%;
	background-color: rgb(198, 198, 198);
	text-align: center;
	position: absolute;
	filter:drop-shadow(0px 0px 1px rgb(111, 111, 111))
	
}

#hubsoutbgsidebgbg{

position: absolute;
width: 5.5%;
left:0.5%;
top:15%;
height:72.5%;
z-index: 5;
opacity: 0.5;
background-color: rgb(0, 0, 0);

}



#img{
	width:90%;
	right:5.5%;
	height:100%;
	top:4%;
	filter:drop-shadow(5px 0px 1px rgb(19, 19, 19));
	
	
	position: absolute;
}


#imgs{
	width:60%;
	right:5.5%;
	height:100%;
	top:4%;
	filter:drop-shadow(0px 0px 5px rgb(0, 0, 0));
	
	
	position: absolute;
}

#imgsx{
	width:20%;
	right:56.5%;
	height:50%;
	opacity:0.20;
	top:11%;
	filter:drop-shadow(0px 0px 5px rgb(0, 0, 0));
	
	z-index: 1;
	position: absolute;
}
#imgsxx{
	width:20%;
	right:56.5%;
	height:50%;
	opacity:0.7 ;
	top:11%;
	z-index: 1;
	filter:drop-shadow(0px 0px 5px rgb(0, 0, 0));
	
	
	position: absolute;
}

#imgsxxq{
	border: 2px solid rgb(159, 159, 159);
	position: absolute;
	width:20%;
	right:67.5%;
	top:75%;
	color:rgba(83, 84, 94, 0.304);
	z-index: 2;
	
	

}

#xxqbind{
	opacity:0.8;
}

#imgsxxe{
	width:20%;
	right:64.5%;
	height:50%;
	top:98%;
	letter-spacing:1px;
	position: absolute;
}

#imglogin{
	width:60%;
	right:20%;
	height:60%;
	top:4%;

	position: absolute;
}

#pxfuel{
	right:0%;
	width:100%;
	height:100%;
	opacity: 0.2;
	position: absolute;
	
}




#contentsecondlist{
	width: 100%;
	
	color:white;
}

#contentblockdiv{
	position: absolute;
	width: 100%;
	right:-13.5%;
	top:10%;
	
}

#card{
	margin-top:7.5%;
	left:-1%;
	position: relative;
	margin-right: -1%;
	
	
	
}


#cardbg{
	background-color: rgb(13, 13, 13);
	opacity: 0.5;
	border:2px solid rgb(13, 13, 13);
	width: 100%;
	height: 100%;
	position: relative;
	margin-right: -1%;
	
}


#ordinary{
	
	opacity: 1;
	
	width: 85%;
	height: 20.5%;
	top: 29%;
	right:7.5%;
	position: absolute;
	margin-right: 0%;
	
	
}

#SYNC{
	
	color:rgb(169, 13, 13);
	height:20%;
	top:0%;	
	font-weight: 700;
	font-family: 'arial narrow';
	opacity: 0.4;
	width: 100%;
	height:100%;
	background-color: rgb(24, 24, 24);
	font-size: 90%;
	letter-spacing: 1px;
	z-index: 20;
	text-transform: uppercase;
	text-align: center;
	position: absolute;
	font-family: 'arial narrow';
}
#contentblock{
	width: 40%;
	height:100%;
	background-color: red;
}
</style>