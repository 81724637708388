<template>
  <v-app>
    <v-main>
      
      
      <div id="top" >
        <div id="foreground"> 
        <VCardTitle>UNLIMITED MONEY GROUP</VCardTitle> 
        
        </div>
       
        
       
        <div v-if="this.$store.state.isAuthenticated">
          <div v-if="showprofiledata">
          
         
          </div>
          </div>
      </div>

      <div id="topup">
       
      <button id="topups" @click="$router.push('/profile')">Profile</button>
      <button id="topupsx" @click="$router.push('/item/none')">Store</button>
      <button id="topupsxs" @click="$router.push('/home')">Home</button>

      
      </div>
          
      <VCardSubtitle id="topupc"><button id="topupce">Vox</button>22,220.00</VCardSubtitle>
     
      <NavBar></NavBar>
      
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import NavBar from './views/NavBar';

import store from './store/index'
var showprofiledata = false
export default {
  name: 'App',

  components:{
    NavBar
  },

  beforeCreate(){
    this.$store.commit('initializeStore')
    //console.log(this.$store.state.isAuthenticated)
    if(this.$store.state.token){
      axios.defaults.headers.common['Authorization'] = 'Token ' + this.$store.state.token
      this.$store.state.isAuthenticated = true
      //console.log('lovebug')
    }else{
      axios.defaults.headers.common['Authorization'] = ''
      this.$store.state.isAuthenticated = false
    }
  },

  data(){
    return{
      MONEY:0,showprofiledata:true

    }
  },

  async mounted(){
    setInterval(this.twin, 200)
    
    if(this.$route.name == 'profile'){
      this.showprofiledata = false
    }else{
      this.showprofiledata = true
    }
    //console.log(this.$route.name, 'perms', this.showprofiledata)
    //this.profile()

  },

  methods:{

    twin(){
      if(this.$route.name == 'profile'){
      this.showprofiledata = false
      }else{
        this.showprofiledata = true
      }
    },
    async Logout(){

      axios
        .post('/api/v1/token/logout/')
        .then(response =>{
          
          
          //console.log('Logged Out')
        
        }).catch(error => {
          if(error.response){
           //console.log(error.response.data)
          }
        })

      axios.defaults.headers.common['Authorization'] = ''
      localStorage.removeItem('token')
      this.$store.commit('removeToken')
      localStorage.removeItem('username')
      localStorage.removeItem('id')
      localStorage.removeItem('linked')
      localStorage.removeItem('apitoken')
      localStorage.removeItem('linkapiu')
      localStorage.removeItem('linkapip')
      localStorage.removeItem('money')
      this.$router.push({path:'/login/user/sign-in'})




      },

    

  }
}
</script>

<style>
body {
            background-color: rgb(223, 158, 105);
        }

        #top{
    position: absolute;
    width: 100%;
    height: 9.5%;
    top: 0%;
    opacity: 1;
    right: 0%;
    z-index: 2;
    background-color: rgb(16, 16, 16);
    
   
}

#topup{
	background-color: rgb(11, 11, 11);
	width:100%;
	height:5.75%;
  
	position: absolute;
	top:10%;
	z-index: 10;
}

#topupc{

	width:100%;
	height:5.75%;
  text-align: right;
	position: absolute;
  color:rgb(157, 157, 157);
	top:11.2%;
  font-size: 100%;
  opacity: 0;
  right:2%;
  letter-spacing: 1px;
  pointer-events: none;
	z-index: 10;
}

#topupce{
  margin-right: 2.5%;
  font-size: 90%;
  letter-spacing: 1px;
  font-weight: 500;
  opacity: 0;
  color:rgb(155, 155, 155);
}

#topups{
	background-color: rgb(11, 11, 11);
	width:100%;
	height:5.75%;
	font-size: 105%;
	position: relative;
	top:17.5%;
	color:rgb(168, 168, 168);
	left:2.5%;
	text-align: left;
	z-index: 1;
}

#topupsx{
	background-color: rgb(11, 11, 11);
	width:100%;
	height:5.75%;
	font-size: 105%;
	position: relative;
	top:-42%;
	color:rgb(168, 168, 168);
	left:7.5%;
	text-align: left;
	z-index: 11;
}
#topupsxs{
	background-color: rgb(11, 11, 11);
	width:100%;
	height:5.75%;
	font-size: 105%;
	position: relative;
	top:-102%;
	color:rgb(168, 168, 168);
	left:12%;
	text-align: left;
	z-index: 30;
}

#profi{
  margin-left: 50%;
  margin-top:2.5%;
  font-size: 110%;
  color:rgb(199, 199, 199);
}

#stori{
  margin-left:57.5%;
  margin-top:-1.75%;
  font-size: 110%;
  color:rgb(199, 199, 199);
}
#godownlog{
  top:50%;
  margin-top: 7%;
}
#foregrounds{
  width:101%;
  height:60%;
  top:35%;
  right:0%;
  pointer-events: none;
  position: absolute;
  opacity: 0.5;


  background-color: rgb(21, 21, 21);
}

#foregroundss{
  width:100%;
  height:60%;
  top:30%;
  right:0%;
  pointer-events: none;
  position: absolute;
  opacity: 0.8;
 
  background-color: rgb(17, 17, 17);
}
#foreground{
  text-align: left;
  width:100%;
  height:10%;
  padding-top:0.75%;
  padding-left: 0.25%;
  font-size: 70%;
  pointer-events: none;
  color:rgb(171, 171, 171);
  position: absolute;
  background-color: rgb(0, 0, 0);
}
#profileviewbg{
    width: 17.5%;
    height:40%;
    right:1%;
    top:43%;
    opacity: 0.8;
    position: absolute;
    border: 2px solid rgb(36, 36, 36);
    background-color: rgb(41, 41, 41);
    filter: drop-shadow(1px 0px 1px black);
}

#profileviewbgbg{
    width: 9.70%;
    height:33.75%;
    right:8.65%;
    top:55%;
    opacity: 0.8;
    position: absolute;
    border: 2px solid rgb(36, 36, 36);
    background-color: rgb(59, 59, 59);
}

#profileview{
    width: 9.5%;
    height:40%;
    right:8.75%;
    top:58.5%;
    opacity: 1;
    text-align: center;
    position: absolute;
    color:rgb(24, 24, 24);



}


#profileviewm{
    width: 7.75%;
    height:32.5%;
    right:18.75%;
    top:55.75%;
    font-weight: 1000;
    opacity: 0.6;
    color:rgb(1, 35, 1);
    text-align: center;
    background-color: rgb(3, 77, 3);
    border:2px solid rgb(3, 47, 17);
    position: absolute;



}

#profileviewnm{
  position: relative;
  margin-top:2%;
  font-weight: 600;
}


#profileviewlogout{
    width: 10%;
    height:35%;
    right:-2%;
    top:53%;
    opacity: 1;
    position: absolute;
    text-align: center;
    background-color: rgb(12, 12, 12);

}

</style>



